import React from "react";
import { Local } from "./local";

import "./styles.form.css";
import { Helmet } from "react-helmet";
import { TextInput } from "./inputs/TextInput/textInput";
import { specialCharacters } from "./inputs/regex";
import { PhoneInput } from "./inputs/TextInput/phoneInput";
import { AskInput } from "./inputs/AskInput/askInput";
import { SelectInput } from "./inputs/SelectInput/selectInput";
import { DateInput } from "./inputs/DateInput/dateInput";
import { CheckBoxInput } from "./inputs/CheckBoxInput/checkboxInput";
import { AydinlatmaMetni } from "../documents";
import { IYSMetni } from "../documents/iys";
import { useMutation } from "@apollo/client";
import { createMultiRegistryFormMutation } from "../../graphql/mutations";

import { ReactComponent as CheckEnvelope } from "../../assets/icons/envelope-circle-check-solid.svg";
import { ReactComponent as XMark } from "../../assets/icons/triangle-exclamation-solid.svg";
import axios from "axios";

// Helmet
function FormHelmet() {
  return (
    <Helmet>
      <title>Kampüs Kreş - Öğrenci Başvuru Formu</title>
      <meta name="title" content="Kampüs Kreş - Öğrenci Başvuru Formu" />
      <meta property="og:title" content="Kampüs Kreş - Öğrenci Başvuru Formu" />
      <meta
        name="twitter:title"
        content="Kampüs Kreş - Öğrenci Başvuru Formu"
      />

      <meta
        name="keywords"
        content="kreş online başvuru, online başvuru, kampüs kreş Öğrenci Başvuru, kampüs kreş Online Başvuru"
      />
    </Helmet>
  );
}

// Language Selector
function LanguageSelector({ language, action }) {
  return (
    <div className="languageSelector">
      <input
        defaultChecked={language === "tr"}
        id="tr"
        type="radio"
        className="languageRadio"
        name="langSelect"
        value="tr"
        onClick={(event) => {
          action(event.currentTarget.value);
        }}
      />
      <div htmlFor="tr" className="languageRadioSelect">
        <label htmlFor="tr">TR</label>
      </div>
      <input
        defaultChecked={language === "en"}
        id="en"
        type="radio"
        className="languageRadio"
        name="langSelect"
        value="en"
        onClick={(event) => {
          action(event.currentTarget.value);
        }}
      />
      <div htmlFor="en" className="languageRadioSelect">
        <label htmlFor="en">EN</label>
      </div>
    </div>
  );
}

// Pagination Info
function PaginationInfo({ at, max, label }) {
  // "@at" returns index so starts from 0, pages start from 1 so always add 1
  return (
    <div className="form_paginationInfo">
      <div className="form_paginationDots">
        {Array.from(Array(max), (_, i) => {
          return (
            <div
              key={i}
              data-completed={at > i}
              data-currentpage={at === i}
              className="paginationDot"
            ></div>
          );
        })}
      </div>
      <h2 className="form_paginationLabel">{label}</h2>
    </div>
  );
}

// Conclusion Pages
// eslint-disable-next-line
function successPage() {}
// eslint-disable-next-line
function errorPage() {}
// eslint-disable-next-line
function existsPage() {}

// eslint-disable-next-line no-extend-native
Date.prototype.toDateInputValue = function () {
  var local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};

// Form Pages
function FirstPage({
  LocalStrings,
  Errors,
  Values: { name, phone, email, location, osb, studentCloseness },
}) {
  const emailValue = {
    get: email.get,
    set: (value) => email.set(value.trim()),
  };

  return (
    <div className="form_fragment">
      <TextInput
        data-error={Errors.get.includes("p1.parent.name")}
        id="parent_name"
        type="name"
        label={LocalStrings.getString("PageOne", "NameLabel")}
        value={name}
        validate={(value) => !specialCharacters.test(value)}
      />
      <PhoneInput
        data-error={Errors.get.includes("p1.parent.phone")}
        id="parent_phone"
        label={LocalStrings.getString("PageOne", "PhoneLabel")}
        value={phone}
      />
      <TextInput
        data-error={Errors.get.includes("p1.parent.email")}
        id="parent_email"
        type="email"
        label={LocalStrings.getString("PageOne", "EmailLabel")}
        value={emailValue}
      />
      <TextInput
        data-error={Errors.get.includes("p1.parent.location")}
        id="parent_location"
        type="district"
        label={LocalStrings.getString("PageOne", "LocationLabel")}
        value={location}
      />
      <AskInput
        data-error={Errors.get.includes("p2.student.studentCloseness")}
        id="student_closeness"
        label={LocalStrings.getString("PageOne", "Closeness", "Label")}
        buttons={{
          first: LocalStrings.getString("PageOne", "Closeness", "FirstButton"),
          second: LocalStrings.getString(
            "PageOne",
            "Closeness",
            "SecondButton"
          ),
        }}
        value={studentCloseness}
      />
      <AskInput
        data-error={Errors.get.includes("p1.parent.osb")}
        id="parent_osb"
        label={LocalStrings.getString("PageOne", "OSB", "Label")}
        buttons={{
          first: LocalStrings.getString("PageOne", "OSB", "FirstButton"),
          second: LocalStrings.getString("PageOne", "OSB", "SecondButton"),
        }}
        value={osb}
      />
    </div>
  );
}
function SecondPage({
  LocalStrings,
  Errors,
  Values: {
    studentCount,
    studentNames,
    studentClassCategory,
    // studentAgeGroups,
    studentBirth,
    studentGender,
  },
}) {
  const now = new Date();
  const nowISO = now.toISOString();

  return (
    <React.Fragment>
      <div className="form_fragment">
        <SelectInput
          data-error={Errors.get.includes("p2.student.studentCount")}
          id="student_count"
          label={LocalStrings.getString("PageTwo", "HowManyLabel")}
          value={studentCount}
          options={[
            { value: 0, label: LocalStrings.getString("Choose") },
            { value: "1", label: "1" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
          ]}
        />
      </div>
      {Array.from(Array(parseInt(studentCount.get)), (_, i) => {
        let studentNameValue = {
          get: () => studentNames.get[i] || "",
          set: (value) =>
            studentNames.set((prev) =>
              prev.map((el, index) => {
                if (i !== index) {
                  return el;
                }

                return value;
              })
            ),
        };
        let studentClassCategoryValue = {
          get: () => studentClassCategory.get[i] || "0",
          set: (value) =>
            studentClassCategory.set((prev) =>
              prev.map((el, index) => {
                if (i !== index) {
                  return el;
                }

                return value;
              })
            ),
        };
        let studentBirthValue = {
          get: () => studentBirth.get[i] || now.toDateInputValue(),
          getActual: () => studentBirth.get[i],
          set: (value) =>
            studentBirth.set((prev) =>
              prev.map((el, index) => {
                if (i !== index) {
                  return el;
                }

                return value;
              })
            ),
        };
        let studentGenderValue = {
          get: () => studentGender.get[i] || null,
          set: (value) =>
            studentGender.set((prev) =>
              prev.map((el, index) => {
                if (i !== index) {
                  return el;
                }

                return value;
              })
            ),
        };

        // Localization
        // const ageGroupOptions = LocalStrings.getString(
        //   "PageTwo",
        //   "AgeGroup",
        //   "Options"
        // );
        const classCategoryOptions = LocalStrings.getString(
          "PageTwo",
          "ClassGroup",
          "Options"
        );

        return (
          <div className="form_fragment">
            <TextInput
              data-error={Errors.get.includes(`p2.student_${i}.studentNames`)}
              id={`${i}_name`}
              type="name"
              label={LocalStrings.getString("PageTwo", "NameLabel")}
              value={studentNameValue}
              validate={(value) => !specialCharacters.test(value)}
            />
            <DateInput
              data-error={Errors.get.includes(`p2.student_${i}.studentBirth`)}
              id={`${i}_birth`}
              label={LocalStrings.getString("PageTwo", "BirthLabel")}
              min={`${parseInt(now.getFullYear()) - 5}-01-01`}
              max={nowISO.substring(0, nowISO.search(/[A-Z]/))}
              value={studentBirthValue}
              onChange={(event) => {
                const val = parseInt(event.target.value);
                if (val < now.getFullYear() - 1) {
                  studentClassCategoryValue.set(0);
                } else {
                  // Kreş ogrencisi
                  studentClassCategoryValue.set(3);
                }
              }}
            />
            {studentBirthValue.getActual() !== null &&
              parseInt(studentBirthValue.get().split("-")[0]) <
                now.getFullYear() - 1 && (
                <SelectInput
                  data-error={Errors.get.includes(
                    `p2.student_${i}.studentClassCategory`
                  )}
                  id={`${i}_classCategory`}
                  label={LocalStrings.getString(
                    "PageTwo",
                    "ClassGroup",
                    "Label"
                  )}
                  options={[
                    { value: 0, label: LocalStrings.getString("Choose") },
                    { value: 1, label: classCategoryOptions[0] },
                    { value: 2, label: classCategoryOptions[1] },
                    // { value: 3, label: classCategoryOptions[2] }, KREŞ
                  ]}
                  value={studentClassCategoryValue}
                />
              )}
            <AskInput
              data-error={Errors.get.includes(`p2.student_${i}.studentGender`)}
              id={`${i}_gender`}
              label={LocalStrings.getString("PageTwo", "Gender", "Label")}
              buttons={{
                first: LocalStrings.getString(
                  "PageTwo",
                  "Gender",
                  "FirstButton"
                ),
                second: LocalStrings.getString(
                  "PageTwo",
                  "Gender",
                  "SecondButton"
                ),
              }}
              value={studentGenderValue}
            />
            {/* <SelectInput
              data-error={Errors.get.includes(
                `p2.student_${i}.studentAgeGroups`
              )}
              id={`${i}_ageGroup`}
              label={LocalStrings.getString("PageTwo", "AgeGroup", "Label")}
              options={[
                { value: 0, label: LocalStrings.getString("Choose") },
                { value: 1, label: ageGroupOptions[0] },
                { value: 2, label: ageGroupOptions[1] },
                { value: 3, label: ageGroupOptions[2] },
                { value: 4, label: ageGroupOptions[3] },
                { value: 5, label: ageGroupOptions[4] },
              ]}
              onChange={(event) => {
                const val = parseInt(event.target.value);
                if (val <= 1) {
                  studentClassCategoryValue.set(3);
                } else {
                  studentClassCategoryValue.set(0);
                }
              }}
              value={studentAgeGroupValue}
            /> */}

            {/* <TextInput
              data-error={Errors.get.includes(
                `p2.student_${i}.studentCloseness`
              )}
              id={`${i}_closeness`}
              type="text"
              label={LocalStrings.getString("PageTwo", "ClosenessLabel")}
              value={studentClosenessValue}
              validate={(value) => !specialCharacters.test(value)}
            /> */}
          </div>
        );
      })}
    </React.Fragment>
  );
}
function ThirdPage({
  LocalStrings,
  Errors,
  Values: { allowedLegal, heardAt, adConsent },
}) {
  const heardOptions = LocalStrings.getString("PageThree", "Heard", "Options");
  return (
    <React.Fragment>
      <div className="form_fragment">
        <SelectInput
          data-error={Errors.get.includes("p3.legal.heardAt")}
          id="legal_heardAt"
          label={LocalStrings.getString("PageThree", "Heard", "Label")}
          value={heardAt}
          options={[
            { value: 0, label: LocalStrings.getString("Choose") },
            { value: 1, label: heardOptions[0] },
            { value: 2, label: heardOptions[1] },
            { value: 3, label: heardOptions[2] },
            { value: 4, label: heardOptions[3] },
            { value: 5, label: heardOptions[4] },
            { value: 6, label: heardOptions[5] },
          ]}
        />
      </div>
      <div className="form_fragment">
        <CheckBoxInput
          data-error={Errors.get.includes("p3.legal.adConsent")}
          id="legal_adConsent"
          label={LocalStrings.getString("PageThree", "AdConsentLabel")}
          popUpPage={IYSMetni}
          value={adConsent}
          languageStrings={LocalStrings}
          required
        />
        <CheckBoxInput
          data-error={Errors.get.includes("p3.legal.legal")}
          id="legal_consent"
          label={LocalStrings.getString("PageThree", "LegalLabel")}
          popUpPage={AydinlatmaMetni}
          value={allowedLegal}
          languageStrings={LocalStrings}
          required
        />
      </div>
    </React.Fragment>
  );
}

export function RegistryFormPage() {
  const VERSION = 2;

  const isMounted = React.useRef(false);

  if (!isMounted.current) {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/formversion`, {
        responseType: "json",
      })
      .then((v) => {
        const formversion = v.data._formVersion;

        if (VERSION !== formversion) {
          window.location.reload(true);
        }
      });
  }

  React.useEffect(() => {
    isMounted.current = true;
  }, []);

  // Language
  const [lang, setLang] = React.useState("tr");
  const [LocalStrings, setLocal] = React.useState(new Local(lang));

  // Pagination
  const [pageIndex, setPageIndex] = React.useState(1);

  // Errors
  const [errors, setErrors] = React.useState([]);

  // Parent Info
  const [parentName, setParentName] = React.useState("");
  const [parentPhone, setParentPhone] = React.useState("");
  const [parentEmail, setParentEmail] = React.useState("");
  const [parentLocation, setParentLocation] = React.useState("");
  const [parentOSB, setParentOSB] = React.useState("");

  // Student Info
  const [studentCount, setStudentCount] = React.useState(0);
  const [studentCloseness, setStudentCloseness] = React.useState("");
  const [studentNames, setStudentNames] = React.useState([
    null,
    null,
    null,
    null,
  ]);
  // const [studentAgeGroups, setStudentAgeGroups] = React.useState([
  //   null,
  //   null,
  //   null,
  //   null,
  // ]);
  const [studentClassCategory, setStudentClassCategory] = React.useState([
    null,
    null,
    null,
    null,
  ]);
  const [studentBirth, setStudentBirth] = React.useState([
    null,
    null,
    null,
    null,
  ]);
  const [studentGender, setStudentGender] = React.useState(["", "", "", ""]);

  // Legal and Info
  const [heardAt, setHeardAt] = React.useState(0);
  const [contactSMS, setContactSMS] = React.useState(false);
  const [contactTEL, setContactTEL] = React.useState(false);
  const [contactMAIL, setContactMAIL] = React.useState(false);
  // const [contact, setContact] = React.useState(false);
  const [allowedLegal, setAllowedLegal] = React.useState(false);
  const [adConsent, setAdConsent] = React.useState(false);

  // GraphQL
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const [formData, setFormData] = React.useState(undefined);
  const [formError, setFormError] = React.useState(undefined);
  const [createForm] = useMutation(createMultiRegistryFormMutation, {
    onCompleted: (data) => {
      setFormData(data); // success
    },
    onError: (error) => {
      setFormError(error.graphQLErrors[0]); // extensions (code, offerCommunucation, offerHomePage), message
    },
  });

  const pages = [
    {
      label: LocalStrings.getString("PageOne", "Label"),
      page: FirstPage,
      params: {
        LocalStrings: LocalStrings,
        Errors: {
          add: addError,
          remove: (input) => removeError(1, input),
          get: errors.filter((v) => v.split(".")[0] === "p1"),
        },
        Values: {
          name: {
            get: parentName,
            set: setParentName,
            errorId: "parent.name",
          },
          phone: {
            get: parentPhone,
            set: setParentPhone,
            errorId: "parent.phone",
          },
          email: {
            get: parentEmail,
            set: setParentEmail,
            errorId: "parent.email",
          },
          location: {
            get: parentLocation,
            set: setParentLocation,
            errorId: "parent.location",
          },
          osb: {
            get: parentOSB,
            set: setParentOSB,
            errorId: "parent.osb",
          },
          studentCloseness: {
            get: studentCloseness,
            set: setStudentCloseness,
            errorId: "student.studentCloseness",
          },
        },
      },
    },
    {
      label: LocalStrings.getString("PageTwo", "Label"),
      page: SecondPage,
      params: {
        LocalStrings: LocalStrings,
        Errors: {
          add: addError,
          remove: (input) => removeError(2, input),
          get: errors.filter((v) => v.split(".")[0] === "p2"),
        },
        Values: {
          studentCount: {
            get: studentCount,
            set: setStudentCount,
            errorId: "student.studentCount",
          },
          studentNames: {
            get: studentNames,
            set: setStudentNames,
            errorId: "student_{i}.studentNames",
          },
          studentClassCategory: {
            get: studentClassCategory,
            set: setStudentClassCategory,
            errorId: "student_{i}.studentClassCategory",
          },
          studentBirth: {
            get: studentBirth,
            set: setStudentBirth,
            errorId: "student_{i}.studentBirth",
          },
          studentGender: {
            get: studentGender,
            set: setStudentGender,
            errorId: "student_{i}.studentGender",
          },
        },
      },
    },
    {
      label: LocalStrings.getString("PageThree", "Label"),
      page: ThirdPage,
      customPageValidator: (values) => {
        let valid = true;
        // check legal
        if (!values.allowedLegal.get) {
          addError(pageIndex, "legal.legal");
          valid = false;
        }

        if (!values.adConsent.get) {
          addError(pageIndex, "legal.adConsent");
          valid = false;
        }

        if (parseInt(values.heardAt.get) === 0) {
          addError(pageIndex, "legal.heardAt");
          valid = false;
        }

        return valid;
      },
      params: {
        LocalStrings: LocalStrings,
        Errors: {
          add: addError,
          remove: (input) => removeError(3, input),
          get: errors.filter((v) => v.split(".")[0] === "p3"),
        },
        Values: {
          heardAt: {
            get: heardAt,
            set: setHeardAt,
            errorId: "legal.heardAt",
          },
          contactSMS: {
            get: contactSMS,
            set: setContactSMS,
            errorId: "legal.contact",
          },
          contactTEL: {
            get: contactTEL,
            set: setContactTEL,
            errorId: "legal.contact",
          },
          contactMAIL: {
            get: contactMAIL,
            set: setContactMAIL,
            errorId: "legal.contact",
          },
          allowedLegal: {
            get: allowedLegal,
            set: setAllowedLegal,
            errorId: "legal.legal",
          },
          adConsent: {
            get: adConsent,
            set: setAdConsent,
            errorId: "legal.adconsent",
          },
        },
      },
      submitPage: true,
    },
  ];

  function addError(page, input) {
    setErrors((oldErrors) => [...oldErrors, `p${page}.${input}`]);
  }
  function removeError(page, input) {
    setErrors((oldErrors) =>
      oldErrors.filter((v) => v !== `p${page}.${input}`)
    );
  }

  const Page = pages[pageIndex - 1];

  function validateValue(value, arrayIndexCount) {
    if (value === undefined || value === null)
      return { valid: false, indexMap: null };
    switch (typeof value) {
      case "string":
        return { valid: value.length !== 0, indexMap: null };
      case "number":
        return { valid: value !== 0, indexMap: null };
      case "boolean":
        return value;
      case "object":
        const indexMapObj = [];
        value.forEach((v, i) => {
          if (i < arrayIndexCount) {
            if (!validateValue(v).valid) {
              indexMapObj.push(i);
            }
          }
        });
        const isIndexMapEmptyObj = indexMapObj.length === 0;
        return {
          valid: isIndexMapEmptyObj,
          indexMap: isIndexMapEmptyObj ? null : indexMapObj,
        };
      case "function":
        const indexMap = [];
        const val = value.get();
        for (var i = 0; i < val.length && i < arrayIndexCount; i++) {
          if (!validateValue(val[i]).valid) {
            indexMap.push(i);
          }
        }
        const isIndexMapEmpty = indexMap.length === 0;
        return {
          valid: isIndexMapEmpty,
          indexMap: isIndexMapEmpty ? null : indexMap,
        };
      default:
        return { valid: false, indexMap: null };
    }
  }

  function validatePageDataAndChangePage() {
    let valid = true;
    if (Page.customPageValidator) {
      if (!Page.customPageValidator(Page.params.Values)) {
        valid = false;
      }
    } else {
      for (let [_, value] of Object.entries(Page.params.Values)) {
        const validation = validateValue(
          value.get,
          Page.params.Values.studentCount?.get || 0
        );
        if (!validation.valid) {
          valid = false;
          if (validation.indexMap === null) {
            addError(pageIndex, value.errorId);
          } else {
            for (let i = 0; i < validation.indexMap.length; i++) {
              addError(
                pageIndex,
                value.errorId.replace("{i}", validation.indexMap[i])
              );
            }
          }
        }
      }
    }

    if (valid) {
      if (Page.submitPage) {
        setSubmitting(true);
        handleSubmit().then(() => {
          setSubmitted(true);
          setSubmitting(false);
        });
      } else {
        setPageIndex((pi) => pi + 1);
      }
    }
  }

  async function handleSubmit() {
    const heard = [
      "İnternet",
      "Instagram",
      "OSB Kanalıyla",
      "Billboard",
      "Tavsiye",
      "Broşür",
    ];

    const students = Array.from(Array(studentCount), (_, i) => ({
      name: studentNames[i],
      birth: studentBirth[i],
      gender: studentGender[i] === "y" ? "girl" : "boy",
      classType: studentClassCategory[i],
      parent: {
        name: parentName,
        phone: parentPhone,
        email: parentEmail,
        district: parentLocation,
        osb: parentOSB === "y" ? true : false,
        closeness: studentCloseness === "y" ? "mother" : "father",
        heardAt: heard[heardAt],
      },
    }));

    await createForm({
      variables: {
        students,
      },
    });

    return {
      // success: formData.success || false,
      success: false,
      // error: formError.message || null,
      error: null,
    };
  }

  React.useEffect(() => {
    errors.forEach((v) => {
      const errorFragments = v.split(".");

      const errorPageIndex = parseInt(v.substring(1, 2)) - 1;
      const errorValueKey = errorFragments[errorFragments.length - 1];

      const errorPage = pages[errorPageIndex];

      let errorValue = errorPage.params.Values[errorValueKey].get;
      if (typeof errorValue === "object") {
        const valueArrayIndex = parseInt(
          errorFragments[1].substring(errorFragments[1].length - 1)
        );

        errorValue = errorValue[valueArrayIndex];
      }

      const validation = validateValue(errorValue);

      if (validation.valid) {
        removeError(
          errorPageIndex + 1,
          `${errorFragments[1]}.${errorFragments[2]}`
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    parentEmail,
    parentName,
    parentOSB,
    parentPhone,
    // studentAgeGroups,
    studentBirth,
    studentClassCategory,
    studentCloseness,
    studentCount,
    studentNames,
  ]);
  React.useEffect(() => {
    if (errors.includes("p3.legal.adConsent") && adConsent) {
      removeError(pageIndex, "legal.adConsent");
    }

    if (errors.includes("p3.legal.heardAt") && heardAt !== 0) {
      removeError(pageIndex, "legal.heardAt");
    }

    if (errors.includes("p3.legal.legal") && allowedLegal) {
      removeError(pageIndex, "legal.legal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heardAt, adConsent, allowedLegal]);

  React.useEffect(() => {
    const nav = document.querySelector(".navWrap");
    nav.scrollIntoView({ behavior: "smooth" });
  }, [pageIndex]);

  function FormShell(props) {
    const { children } = props;

    return (
      <main id="studentapplication">
        <form>
          <FormHelmet />
          <div className="form_information">
            <span className="form_year-information">
              {LocalStrings.getString("FormYear")}
            </span>
            <div className="form_title">
              <h1>{LocalStrings.getString("FormLabel")}</h1>
              <LanguageSelector
                language={lang}
                action={(language) => {
                  setLang(language);
                  setLocal(new Local(language));
                }}
              />
            </div>
          </div>
          <div className="form_fragment_pagination">{children}</div>
        </form>
      </main>
    );
  }

  const [contactModal, setContactModal] = React.useState(false);

  function closeModal() {
    setContactModal(false);
  }
  function showModal() {
    setContactModal(true);
  }

  if (submitted && formError)
    // SUBMITTED WITH ERROR
    return (
      <FormShell>
        <div data-open={contactModal} className="contactdialog">
          <div className="contactdialog_fragment">
            <p>E-Mail:</p>
            <span>
              <a className="modalAnchor" href="mailto:kayit@kampuskres.com.tr">
                kayit@kampuskres.com.tr
              </a>
            </span>
          </div>
          <div className="contactdialog_fragment">
            <p>TEL:</p>
            <span>
              <a className="modalAnchor" href="tel:+90 216 420 75 75">
                +90 216 420 75 75
              </a>
            </span>
          </div>
          <hr></hr>
          <button
            onClick={() => {
              closeModal();
            }}
          >
            {LocalStrings.getString("ContactDialog", "ButtonLabel")}
          </button>
        </div>
        <div className="form_fragment">
          <div className="form_message-red">
            <XMark />
            <p className="form_errorMessage">
              {lang === "tr" ? formError.message : formError.extensions.en}
            </p>
          </div>
        </div>
        <div className="form_pagination_buttons">
          {formError.extensions.offerHomePage && (
            <button
              onClick={() => {
                window.location.href = "https://kampuskres.com.tr";
              }}
              type="button"
              className="button white"
            >
              {LocalStrings.getString("Pagination", "Home")}
            </button>
          )}

          {formError.extensions.offerCommunucation && (
            <button
              style={{ alignSelf: "flex-end" }}
              onClick={() => {
                showModal();
              }}
              type="button"
              className="button blue"
            >
              {LocalStrings.getString("Pagination", "Contact")}
            </button>
          )}
        </div>
      </FormShell>
    );

  if (submitted && formData.createMultiRegistryForm.success)
    // SUBMITTED WITHOUT ERRORS
    return (
      <FormShell>
        <div className="form_fragment">
          <div className="form_message-green">
            <CheckEnvelope />
            <p className="form_errorMessage">
              {LocalStrings.getString("Output", "Success")}
            </p>
          </div>
        </div>
        <div className="form_pagination_buttons">
          <button
            onClick={() => {
              window.location.href = "https://kampuskres.com.tr";
            }}
            type="button"
            className="button blue"
          >
            {LocalStrings.getString("Pagination", "Home")}
          </button>
        </div>
      </FormShell>
    );

  return (
    // NORMAL FORM
    <main id="studentapplication">
      <form>
        <FormHelmet />
        <div className="form_information">
          <span className="form_year-information">
            {LocalStrings.getString("FormYear")}
          </span>
          <div className="form_title">
            <h1>{LocalStrings.getString("FormLabel")}</h1>
            <LanguageSelector
              language={lang}
              action={(language) => {
                setLang(language);
                setLocal(new Local(language));
              }}
            />
          </div>
          <PaginationInfo
            at={pageIndex - 1}
            max={pages.length}
            label={pages[pageIndex - 1].label}
          />
        </div>
        <div className="form_fragment_pagination">
          {Page.page(Page.params)}
          <div className="form_pagination_buttons">
            {
              // back button
              pageIndex > 1 && (
                <button
                  style={{
                    opacity: submitted || submitting ? 0.5 : 1,
                    cursor: submitted || submitting ? "not-allowed" : "pointer",
                  }}
                  disabled={submitted || submitting}
                  onClick={() => {
                    if (!submitting && !submitted) {
                      setPageIndex((pi) => pi - 1);
                    }
                  }}
                  type="button"
                  className="button white"
                >
                  {LocalStrings.getString("Pagination", "Back")}
                </button>
              )
            }
            {
              // next button
              pageIndex < pages.length && (
                <button
                  style={{ alignSelf: "flex-end" }}
                  onClick={validatePageDataAndChangePage}
                  type="button"
                  className="button blue"
                >
                  {LocalStrings.getString("Pagination", "Next")}
                </button>
              )
            }
            {
              // submit button
              Page.submitPage === true && (
                <button
                  style={{
                    alignSelf: "flex-end",
                    opacity: submitted || submitting ? 0.5 : 1,
                    cursor: submitted || submitting ? "not-allowed" : "pointer",
                  }}
                  disabled={submitted || submitting}
                  onClick={() => {
                    if (!submitting && !submitted) {
                      validatePageDataAndChangePage();
                    }
                  }}
                  type="button"
                  className="button blue"
                >
                  {LocalStrings.getString("Pagination", "Submit")}
                </button>
              )
            }
          </div>
        </div>
      </form>
    </main>
  );
}
