let current = new Date().getFullYear();
let years = [
  `${current - 1} - ${current}`,
  (current - 2).toString(),
  (current - 3).toString(),
  (current - 4).toString(),
  (current - 5).toString(),
];

const LocalStrings = {
  tr: {
    FormLabel: "Öğrenci Başvuru Formu",
    FormYear: "2025 - 2026 Eğitim Öğretim Dönemi",
    Choose: "Seç",
    Pagination: {
      Back: "Geri",
      Next: "Sonraki",
      Submit: "Formu Gönder",
      Home: "Ana Sayfaya Dön",
      Contact: "Bizimle İletişime Geç",
    },
    PageOne: {
      Label: "Velinin Bilgileri",
      NameLabel: "Ad Soyad",
      PhoneLabel: "Telefon Numarası",
      EmailLabel: "E-Mail Adresi",
      LocationLabel: "Yaşadığınız İlçe",
      OSB: {
        Label: "OSB içinde mi çalışıyorsunuz?",
        FirstButton: "Evet",
        SecondButton: "Hayır",
      },
      ClosenessLabel: "Öğrencinizle Yakınlık Dereceniz (Anne, Baba, vs.)",
      Closeness: {
        Label: "Öğrencilerinizle yakınlık dereceniz",
        FirstButton: "Annesi",
        SecondButton: "Babası",
      },
    },
    PageTwo: {
      Label: "Ögrencinin Bilgileri",
      NameLabel: "Ad Soyad",
      HowManyLabel: "Kaç öğrenci kayıt edeceksiniz?",
      AgeGroup: {
        Label: "Yaş Grubu",
        Options: [
          "Kreş (0-2)",
          "2 Yaş",
          "3 Yaş",
          "4 Yaş",
          "İlkokula Hazırlık (5-6)",
          // `${years[0]} Doğumlular`,
          // `${years[1]} Doğumlular`,
          // `${years[2]} Doğumlular`,
          // `${years[3]} Doğumlular`,
          // `${years[4]} Doğumlular`,
        ],
      },
      ClassGroup: {
        Label: "Sınıf Kategorisi",
        Options: [
          "Anadil Sınıfları",
          "Çift Dilli Eğitim Sınıfları",
          "Kreş Bölümü",
        ],
      },
      Gender: {
        Label: "Cinsiyet",
        FirstButton: "Kız",
        SecondButton: "Erkek",
      },
      BirthLabel: "Doğum Tarihi",
    },
    PageThree: {
      Label: "Bilgi ve İzinler",
      Heard: {
        Label: "Bizi nereden duydunuz?",
        Options: [
          "İnternet",
          "Instagram",
          "OSB Kanalıyla",
          "Billboard",
          "Tavsiye",
          "Broşür",
        ],
      },
      AdConsentLabel: "İleti Yönetim Sistemi'ni onaylamak için tıklayınız.",
      LegalLabel:
        "KVKK Aydınlatma ve Açık Rıza metnini okumak ve onaylamak için tıklayınız.",
    },
    Output: {
      Success:
        "Formunuz tarafımıza ulaşmıştır ve en kısa zamanda sizinle iletişime geçilecektir.",
    },
    ContactDialog: {
      ButtonLabel: "Tamam",
    },
    Legal: {
      Agree: "Onaylıyorum",
      Cancel: "Vazgeç",
      ScrollLabel: "Onaylamak için metnin sonuna geliniz",
    },
  },
  en: {
    FormLabel: "Student Application Form",
    FormYear: "2025 - 2026 Academic Period",
    Choose: "Choose",
    Pagination: {
      Back: "Back",
      Next: "Next",
      Submit: "Submit",
      Home: "Go to Home Page",
      Contact: "Contact Us",
    },
    PageOne: {
      Label: "Parents' Information",
      NameLabel: "Full Name",
      PhoneLabel: "Phone Number",
      EmailLabel: "E-Mail Address",
      LocationLabel: "Your Town",
      ClosenessLabel: "Your closeness to the student (Mother, Father, etc.)",
      Closeness: {
        Label: "Your closeness to the students",
        FirstButton: "Mother",
        SecondButton: "Father",
      },
      OSB: {
        Label: "Are you working at OSB?",
        FirstButton: "Yes",
        SecondButton: "No",
      },
    },
    PageTwo: {
      Label: "Student's Information",
      NameLabel: "Full Name",
      HowManyLabel: "How many students are you registering?",
      AgeGroup: {
        Label: "Age Group",
        Options: [
          "Nursery (0-2)",
          "2-Year-olds",
          "3 Year olds",
          "4 Year olds",
          "Preparation Class (5-6)",
        ],
      },
      ClassGroup: {
        Label: "Class Group",
        Options: [
          "Non-Bilingual Education Classes",
          "Bilingual Education Classes",
          "Nursery",
        ],
      },
      Gender: {
        Label: "Gender",
        FirstButton: "Female",
        SecondButton: "Male",
      },
      BirthLabel: "Birth Date",
    },
    PageThree: {
      Label: "Info and Consent",
      Heard: {
        Label: "How did you find us?",
        Options: [
          "Internet",
          "Instagram",
          "Through OSB",
          "Billboard",
          "Recommendation",
          "Brochure",
        ],
      },
      AdConsentLabel:
        "Click to allow İleti Yönetim Sistemi (Message Management System)",
      LegalLabel:
        "Click to allow KVKK Clarification and Explicit Consent document",
    },
    Output: {
      Success:
        "Your application form is submitted, you will be contacted shortly.",
    },
    ContactDialog: {
      ButtonLabel: "Okay",
    },
    Legal: {
      Agree: "I Agree",
      Cancel: "Cancel",
      ScrollLabel: "Scroll to agree on the document",
    },
  },
};

export class Local {
  lang = "tr";

  getString(...args) {
    let outcome = null;

    for (var i = 0; i < args.length; i++) {
      const argument = args[i];

      if (i === 0) {
        outcome = LocalStrings[this.lang][argument];
      } else {
        if (outcome !== null) {
          outcome = outcome[argument];
        }
      }
    }

    return outcome;
  }

  constructor(lang) {
    this.lang = lang;
  }
}
